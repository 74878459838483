<template>
	<div class="container emp-profile">
        <form method="post">
            <div class="row">
                <div class="col-md-4">
                    <div class="profile-img">
                        <img :src="this.imgAvatarProfile.imageUrl" v-if="this.imgAvatarProfile != null" alt="" />
                       
                        <div class="file btn btn-lg btn-primary">
                            {{ this.$t('pages.account.changephoto') }}
                            <input type="file" name="file" @change="onChangeAvatar" />
                        </div>
                        <img :src="this.previewImage" alt="" />
                        <br />
                        <CButton type="button" id="btn-savechangeavatar" v-if="this.isChangeAvatar" color="primary" class="btn-primary" @click="onSavechangeavatar">{{ $t('common.save') }}</CButton>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="profile-head" v-if="viewingProfile != null">                       
                        <!--<p class="proile-rating">{{ this.$t('pages.account.balance') }} : <span v-if="showWalletInfo">{{ $func.formatCurrency(this.viewingProfile.walletInfo.amount) }}</span></p>
                        <p class="proile-rating">{{ this.$t('pages.account.points') }} : <span v-if="showWalletInfo">{{ $func.formatNumber(this.viewingProfile.walletInfo.rewardPoints) }}</span></p>-->
                        <p class="proile-rating">
                            {{ this.viewingProfile.userInfo.userName }} :
                            <CButton :class="profileStatusCss(this.viewingProfile.userInfo.profileStatus)" style="cursor:default" v-text="profileStatusText(this.viewingProfile.userInfo.profileStatus)" />
                            <button type="button" name="add-shippingAddress" class="btn btn-primary" @click="onAddShippingAddress">
                                {{ $t('common.addShippingAddress') }}
                            </button>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                 
                </div>
            </div>

            <div class="row" style="height: 20px"></div>
            <CRow>
                <CCol sm="6">
                    <GereralProfileInfo></GereralProfileInfo>
                </CCol>
                <CCol sm="6">
                    <ProfileImageRepo></ProfileImageRepo>
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="12">
                    <ProfileBankManagement></ProfileBankManagement>
                </CCol>
            </CRow>

            <CRow v-if="isShowDepositWithdrawalMenu">
                <CCol sm="12">
                    <Accordion :headerTitle="$t('pages.account.accumulatedPointsInfomation')"  v-if="viewingProfile != null" expand>
                        <template v-slot:body>
                            <div class="row" v-if="viewingProfile.walletInfo != null">
                                <div class="col-md-6">
                                    <label>{{ $t('pages.account.balance') }}</label>
                                </div>
                                <div class="col-md-6">
                                    <p>{{  $func.formatCurrency(viewingProfile.walletInfo.amount) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <label>{{ $t('pages.account.points') }}</label>
                                </div>
                                <div class="col-md-6">
                                    <p>{{  $func.formatNumber(viewingProfile.walletInfo.rewardPoints) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <label>{{ $t('pages.account.numOfShares') }}</label>
                                </div>
                                <div class="col-md-6">
                                    <p>{{  $func.formatNumber(viewingProfile.walletInfo.numOfShares) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <label>{{ $t('common.debtPoints') }}</label>
                                </div>
                                <div class="col-md-6">
                                    <p>{{  $func.formatNumber(viewingProfile.walletInfo.debtPoints) }}</p>
                                </div>
                            </div>
                            <div class="form-actions clearfix">
                                <CButton type="button" color="primary" class="btn-primary" @click="onDeposit">{{ $t('pages.account.deposit') }} </CButton>
                                <CButton type="button" color="primary" class="btn-primary" @click="onWithdrawal">{{ $t('common.withdrawal') }} </CButton>
                                <!--<CButton type="button" color="primary" class="btn-primary" @click="onDepositWithdrawalHistory">{{ $t('pages.deposit.depositWithdrawalHistory') }}</CButton>-->
                                <CButton type="button" color="primary" class="btn-primary" @click="onShowRewardPoints">{{ $t('common.historyPoints') }}</CButton>
                                <!--<CButton type="button" color="primary" class="btn-primary">{{ $t('pages.account.exchangePoints') }}</CButton>-->
                            </div>
                        </template>
                    </Accordion>
                </CCol>
            </CRow>
        </form>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import i18n from '@/plugins/i18n'

    import Accordion from '@/components/Accordion.vue'
    import GereralProfileInfo from '../profile/_GeneralInfo.vue'
    import ProfileImageRepo from '../profile/_ImageRepo.vue'
    import ProfileBankManagement from '../profile/_BankManagement.vue'

	export default {
        name: 'Profile',
        data() {
            return {
                isChangeAvatar: false,
                previewImage: null,
                file: null
            }
        },
        components: {
            Accordion,
            GereralProfileInfo,
            ProfileImageRepo,
            ProfileBankManagement
		},
        computed: {            
			...mapGetters('auth', ['user']),
            ...mapGetters('profile', ['viewingProfile', 'imgAvatarProfile']),

            isShowDepositWithdrawalMenu() {
                if (this.$store.state.auth.user.role === 'admin')
                    return false;
                if (this.$store.state.auth.user.role === 'agent' || this.$store.state.auth.user.role === 'user')
                    return true;

                return false;
            }
		},
        mounted() {
            this.$store.dispatch("profile/getUserProfileAllInfomation", parseInt(this.$store.state.auth.user.sub));
            this.$store.dispatch("profile/getAvatarLink", parseInt(this.$store.state.auth.user.sub));
		},
		methods: {
			onChangeProfile() {
                this.$router.push('/users/editprofile/' + parseInt(this.$store.state.auth.user.profileId));
            },
            onVerifyAccount() {
                this.onChangeProfile();
            },
            getGender(type) {
                return type === 1 ? i18n.t('pages.account.male')
                    : type === 2 ? i18n.t('pages.account.female')
                        : i18n.t('pages.account.other')
            },
            profileStatusText(status) {
                return status === 1 ? i18n.t('pages.account.waitingForVerification')
                    : status === 2 ? 'Đã từ chối xác minh' //i18n.t('pages.account.verified')
                        : i18n.t('pages.account.notVerified')
            },
            profileStatusCss(status) {
                if (status === 1)
                    return "btn-success";              
                else
                    return "btn-danger";
            },
            onDeposit() {
                this.$router.push('/assets/deposit');
            },
            onWithdrawal() {
                this.$router.push('/assets/withdrawal');
            },
            onDepositWithdrawalHistory() {
                this.$router.push('/assets/deposithistory');
            },
            onShowRewardPoints() {
                this.$router.push('/assets/rewardpoints');
            },
            showWalletInfo() {
                if (this.viewingProfile == null || this.viewingProfile.walletInfo == null)
                    return false;
                return true;
            },
            onCancel() {
                this.$router.push('/users/profile');
            },
            async onChangeAvatar(e) {
                this.file = e.target.files[0];               
                const reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = e => {
                    this.previewImage = e.target.result;
                    this.isChangeAvatar = true;
                };
            },
            async onSavechangeavatar() {
                var retVal = await this.$store.dispatch("profile/saveChangeAvatar", { userId: this.$store.state.auth.user.sub, file: this.file });
                if (retVal == true) {
                    this.isChangeAvatar = false;
                    this.$store.dispatch("profile/getAvatarLink", parseInt(this.$store.state.auth.user.sub));
                }
            },
            onAddShippingAddress() {
                this.$router.push('/address/add-address');
            }
        },
	}
</script>
<style scoped>
    .btn-primary {
        margin-left: 5px;
    }
   /* body {
        background: -webkit-linear-gradient(left, #3931af, #00c6ff);
    }*/

    .emp-profile {
        padding: 3%;
        margin-top: 3%;
        margin-bottom: 3%;
        border-radius: 0.5rem;
        background: #fff;
    }

    .profile-img {
        text-align: center;
    }

        .profile-img img {
            width: 70%;
            height: 100%;
        }

        .profile-img .file {
            position: relative;
            overflow: hidden;
            margin-top: -20%;
            width: 70%;
            border: none;
            border-radius: 0;
            font-size: 15px;
            background: #212529b8;
        }

            .profile-img .file input {
                position: absolute;
                opacity: 0;
                right: 0;
                top: 0;
            }

    .profile-head h5 {
        color: #333;
    }

    .profile-head h6 {
        color: #0062cc;
    }

    .profile-edit-btn {
        border: none;
        border-radius: 1.5rem;
        width: 70%;
        padding: 2%;
        font-weight: 600;
        color: #6c757d;
        cursor: pointer;
    }

    .proile-rating {
        font-size: 12px;
        color: #818182;
        margin-top: 5%;
    }

        .proile-rating span {
            color: #495057;
            font-size: 15px;
            font-weight: 600;
        }

    .profile-head .nav-tabs {
        margin-bottom: 5%;
    }

        .profile-head .nav-tabs .nav-link {
            font-weight: 600;
            border: none;
        }

            .profile-head .nav-tabs .nav-link.active {
                border: none;
                border-bottom: 2px solid #0062cc;
            }

    .profile-work {
        padding: 14%;
        margin-top: -15%;
    }

        .profile-work p {
            font-size: 12px;
            color: #818182;
            font-weight: 600;
            margin-top: 10%;
        }

        .profile-work a {
            text-decoration: none;
            color: #495057;
            font-weight: 600;
            font-size: 14px;
        }

        .profile-work ul {
            list-style: none;
        }

    .profile-tab label {
        font-weight: 600;
    }

    .profile-tab p {
        font-weight: 600;
        color: #0062cc;
    }
</style>