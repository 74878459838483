<template>
	<Accordion :headerTitle="$t('pages.account.accountInfomation')" v-if="viewingProfile != null" :showFooter="true">
		<template v-slot:body>
			<CForm v-if="viewMode == 'view'">
				<div>
					<div class="row">
						<div class="col-md-6">
							<label>{{ $t('common.userName') }}</label>
						</div>
						<div class="col-md-6">
							<p>{{ viewingProfile.userInfo.userName }}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label>{{ $t('pages.account.fullName') }}</label>
						</div>
						<div class="col-md-6">
							<p>{{ viewingProfile.userInfo.fullName }}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label>{{ $t('common.email') }}</label>
						</div>
						<div class="col-md-6">
							<p>{{ viewingProfile.userInfo.email }}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label>{{ $t('common.phone') }}</label>
						</div>
						<div class="col-md-6">
							<p>{{ viewingProfile.userInfo.phoneNumber }}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label>{{ $t('common.address') }}</label>
						</div>
						<div class="col-md-6">
							<p>{{ viewingProfile.userInfo.address }}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label>{{ $t('pages.account.cmnd') }}</label>
						</div>
						<div class="col-md-6">
							<p>{{ viewingProfile.userInfo.cmnd }}</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label>{{ $t('pages.account.gender') }}</label>
						</div>
						<div class="col-md-6">                                                                                     
							<p>
								{{ viewingProfile.userInfo.gender == 1 ? $t('pages.account.male'): viewingProfile.userInfo.gender == 2 ? $t('pages.account.female'): "" }}
							</p>
						</div>
					</div>
				</div>
			</CForm>

			<CForm v-if="viewMode == 'edit'">
				<CInput :label="$t('common.email')" :value="values.email"
						horizontal
						:isValid="!errors.email" :invalidFeedback="errors.email">
				</CInput>

				<CInput :label="$t('pages.account.fullName')" v-model="values.fullName"
						horizontal 
						:isValid="!errors.fullName" :invalidFeedback="errors.fullName">
				</CInput>
				<CInput :label="$t('common.phone')" v-model="values.phoneNumber" horizontal
				:isValid="!errors.phoneNumber" :invalidFeedback="errors.phoneNumber">
				</CInput>

				<CInput :label="$t('common.address')" v-model="values.address"
						horizontal
						:isValid="!errors.address" :invalidFeedback="errors.address">
				</CInput>

				<div role="group" class="form-group form-row">
					<label class="col-sm-3 col-form-label">{{ $t('pages.account.gender') }}</label>
					<div class="col-sm-9 input-group">
						<select name="ddlProducts" class="form-control" v-model="values.gender">
							<option value="0">{{ $t('pages.account.selectGender') }}</option>
							<option value="1">{{ $t('pages.account.male') }}</option>
							<option value="2">{{ $t('pages.account.female') }}</option>
						</select>
					</div>
				</div>

				<CInput :label="$t('pages.account.cmnd')" v-model="values.cmnd"
						horizontal>
				</CInput>

				<CInput :label="$t('pages.account.dayOfBirth')" type="date" v-model="values.dayOfBirth"
						horizontal>
				</CInput>								
			</CForm>
		</template>
		<template #footer>
			<CButton v-if="viewMode === 'view'" component="a" color="link" role="button" @click="onBeginEdit()">Thay đổi</CButton>
			<CButton v-if="viewMode === 'edit'" color="primary" @click="onSaveGeneralProfile()" class="mx-2">Lưu lại</CButton>
			<CButton v-if="viewMode === 'edit'" color="danger" @click="onCancelEdit()">Bỏ qua</CButton>
		</template>
	</Accordion>
</template>

<script>
	import { mapGetters, mapActions, mapState } from 'vuex'
	import i18n from '@/plugins/i18n'
    import Accordion from '@/components/Accordion.vue'
	import _ from 'lodash'
	import * as yup from 'yup'

	const userInfoSchema = yup.object().shape({
		email: yup.string().email('Email không hợp lệ'),
        fullName: yup.string().required("Nhập họ và tên đầy đủ"),
		phoneNumber: yup.string().required("Nhập số điện thoại"),
        address: yup.string().required("Nhập địa chỉ"),
    });

	const initialValues =  {
					fullName: '',
					phoneNumber: '',
					address: '',
					gender: '',
					cmnd: '',
					dayOfBirth: '',
				};
	const initialErrors = {
					fullName: '',
					phoneNumber: '',
					address: '',
					email: '',
				};

	export default {
        name: 'ProfileGeneralInfo',
        data() {
            return {
                viewMode: 'view',

				values: initialValues,
				errors: initialErrors,
            }
        },
        components: {
            Accordion,
		},
        computed: {            
			...mapGetters('auth', ['user']),
            ...mapGetters('profile', ['viewingProfile']),
		},
        mounted() {
		},
		methods: {
			...mapActions('profile', ['saveGeneralProfileInfo']),
			onBeginEdit(){
				Object.assign(this.values, this.viewingProfile.userInfo);
				this.viewMode = "edit";
			},
			onCancelEdit(){
				this.viewMode = "view";
				this.values = {...initialValues};
				this.errors = {...initialErrors};
			},

			async validateAll() {
				console.log("CONTEXT VALIDATE ALL",this) 
				try {
					this.errors = {...initialErrors};
                    await userInfoSchema.validate(this.values, { abortEarly: false });
					return true;
                }
                catch (err) {
                    _.forEach(err.inner, error => {
                        this.errors[error.path] = error.message;
                    })
					console.log("all errors", this.errors)
                    return false;
                }
			},
			async onSaveGeneralProfile(){
				var isValid = await this.validateAll();
				if(!isValid)
					return;
				
				var result = await this.saveGeneralProfileInfo(this.values);
				if(result == true)
					this.onCancelEdit();
			},
			formatDate(value) {
                return this.$moment(value).format('DD-MM-YYYY');
			}
        },
	}
</script>
