<template>
	<div>
		<Accordion headerTitle="Kho ảnh" v-if="editingProfile != null">
				<template v-slot:body>
					<CRow v-if="pictureModels != null && pictureModels.length > 0">
						<CCol sm="12">
							<CCard>
								<CCardHeader>
									<strong>{{ $t('pages.account.photoOfIdentityCard') }} </strong>
								</CCardHeader>
								<CCardBody>
									<CDataTable :items="pictureModels"
												:fields="fields">
										<template #cmnd_image="{item}">
											<td>
												<a :href="item.fullSizeImageUrl" target="_blank">
													<img :src="item.thumbImageUrl" width="175"/>
												</a>
												
											</td>
										</template>
										<template #picture_actions="{item}">
											<td class="py-2">
												<CButton color="primary"
														 variant="outline"
														 square
														 size="sm"
														 @click="onDeleteImage(item)">
													{{ $t('common.remove') }}
												</CButton>
											</td>
										</template>
										
									</CDataTable>
								</CCardBody>
							</CCard>
						</CCol>
					</CRow>

					<!-- Upload image -->
					<CRow>
						<CCol sm="12">
							<CCard>
								<CCardHeader>
									<strong>{{ $t('pages.account.uploadPhotoOfIdentityCard') }}</strong>
								</CCardHeader>
								<CCardBody>
									<div>
										<UploadImages ref="uploadimages" :max="2" @change="handleIdentityIdCardImages" :uploadMsg="$t('common.uploadImageMessage')" />
									</div>
									<div class="form-actions">
										<CButton v-if="files != null && files.length > 1" type="button" color="primary" @click="onUploadIdentityIdCardImages">{{ $t('pages.account.uploadIdentityCardImages') }}</CButton>										
									</div>
								</CCardBody>
							</CCard>
						</CCol>
					</CRow>
				</template>
			</Accordion>
			<Confirmation ref="confirmation"></Confirmation>
	</div>
</template>

<script>
	import { mapGetters, mapActions, mapState } from 'vuex'
    import i18n from '@/plugins/i18n'
	import UploadImages from "vue-upload-drop-images"
    import Accordion from '@/components/Accordion.vue'
	import Confirmation from '@/components/Confirmation.vue'

	export default {
		name: 'ProfileImageRepo',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
				files: null,
                fields: [                      // Array of column object data                  
                    { key: "cmnd_image", label: i18n.t('pages.account.frontAndBackImage') },
                    {
                        key: 'picture_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ],
			}
		},
        components: {
			UploadImages,
            Accordion,
			Confirmation,       
		},
		computed: {
           // ...mapGetters('accountProfile'),
            ...mapGetters('auth', ['user']),
			...mapGetters('profile', ['editingProfile', 'pictureModels']),			
        },
        methods: {
            async onUpdateProfile() {
				//console.log("Update Progile.......");

                var retVal = await this.$store.dispatch("profile/saveUserProfile");
                if (retVal == true) {
                    this.$router.go(-1);
                }
            },
           
            async handleIdentityIdCardImages(files) {
				this.files = files;				
			},
            async onUploadIdentityIdCardImages() {
                if (this.files == null || this.files.length < 2) {
                    //this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.confirmUploadimage') }, { root: true });
                    this.$store.dispatch('alert/error', this.$t('pages.account.confirmUploadimage'), { root: true });
                    return;
				}
				               
				//var retVal = await this.$store.dispatch("profile/uploadIdentityIdCardImages", { userId: this.editingProfile.id, files: this.files });

                var retVal = await this.$store.dispatch("profile/uploadIdentityIdCardImages", { userId: this.$store.state.auth.user.sub, files: this.files });
               // console.log("uploadIdentityIdCardImage result ==: ", retVal);

                if (retVal === true || retVal.success === true) {
                    //this.$store.dispatch("profile/getAllIdentityIdCardImages", this.editingProfile.id);
                    this.$store.dispatch("profile/getAllIdentityIdCardImages", this.$store.state.auth.user.sub);
					this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticesUploadimage') }, { root: true });

					this.$refs.uploadimages.reset();
					//this.$refs.uploadimages.value=null;
				}
				else {
                    this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.account.noticesUploadimageError') }, { root: true });
                }
			},
		
            onDeleteImage(item) {
                this.$refs.confirmation.show(i18n.t('common.confirmDelete'), async () => {                    
					var retVal = await this.$store.dispatch("accountProfile/deteIdentityIdCardImages", item.id);                   
                    if (retVal == true) {
                        this.$store.dispatch("profile/getAllIdentityIdCardImages");
                    }
                });
            },
        },
		mounted(){
            this.$store.dispatch("profile/getAllIdentityIdCardImages", this.$store.state.auth.user.sub);
		}
    }
</script>