<template>
	<Accordion :headerTitle="$t('pages.account.bankAccountInfomation')" v-if="viewingProfile != null" expand>
		<template v-slot:body>                            
			<div v-if="viewingProfile.bankList != null">
				<CRow v-for="(item) in viewingProfile.bankList" v-bind:key="item.id">
					<CCol sm="2">
						<b> {{$t('pages.bank.account')}} : {{ item.bankCode }}</b>
					</CCol>
					<CCol sm="6">
						<div class="row">
							<div class="col-md-6">
								<b><label>{{ $t('pages.bank.accountName') }}</label></b>
							</div>
							<div class="col-md-6">
								<p><b>{{  item.accountName }}</b></p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>{{ $t('pages.bank.accountNo') }}</label>
							</div>
							<div class="col-md-6">
								<p>{{  item.accountNo }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>{{ $t('pages.bank.bankName') }}</label>
							</div>
							<div class="col-md-6">
								<p>{{ item.bankName }}</p>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<label>{{ $t('pages.bank.branch') }}</label>
							</div>
							<div class="col-md-6">
								<p>{{  item.branch }}</p>
							</div>
						</div>
					</CCol>
				</CRow>                              
			</div>
			<div class="form-actions clearfix">
				<CButton type="button" color="primary" class="btn-primary" @click="onAddBankAccount">{{ $t('pages.account.addBankAccount') }}</CButton>                                
			</div>
		</template>
	</Accordion>
</template>

<script>
	import { mapGetters, mapActions,mapState } from 'vuex'
	import i18n from '@/plugins/i18n'

    import Accordion from '@/components/Accordion.vue'

	export default {
        name: 'ProfileBankManagement',
        data() {
            return {
                isChangeAvatar: false,
                previewImage: null,
                file: null
            }
        },
        components: {
            Accordion,
		},
        computed: {            
			...mapGetters('auth', ['user']),
            ...mapGetters('profile', ['viewingProfile', 'imgAvatarProfile']),

            isShowDepositWithdrawalMenu() {
                if (this.$store.state.auth.user.role === 'admin')
                    return false;
                if (this.$store.state.auth.user.role === 'agent' || this.$store.state.auth.user.role === 'user')
                    return true;

                return false;
            }
		},
        mounted() {
            this.$store.dispatch("profile/getUserProfileAllInfomation", parseInt(this.$store.state.auth.user.sub));
            this.$store.dispatch("profile/getAvatarLink", parseInt(this.$store.state.auth.user.sub));
		},
		methods: {
			onChangeProfile() {
                this.$router.push('/users/editprofile/' + parseInt(this.$store.state.auth.user.profileId));
            },
            onVerifyAccount() {
                this.onChangeProfile();
            },
            getGender(type) {
                return type === 1 ? i18n.t('pages.account.male')
                    : type === 2 ? i18n.t('pages.account.female')
                        : i18n.t('pages.account.other')
            },
            profileStatusText(status) {
                return status === 1 ? i18n.t('pages.account.waitingForVerification')
                    : status === 2 ? i18n.t('pages.account.verified')
                        : i18n.t('pages.account.notVerified')
            },
            profileStatusCss(status) {
                if (status === 2)
                    return "btn-success";              
                else
                    return "btn-danger";
            },
            onDeposit() {
                this.$router.push('/assets/deposit');
            },
            onWithdrawal() {
                this.$router.push('/assets/withdrawal');
            },
            onDepositWithdrawalHistory() {
                this.$router.push('/assets/deposithistory');
            },
            onShowRewardPoints() {
                this.$router.push('/assets/rewardpoints');
            },
            showWalletInfo() {
                if (this.viewingProfile == null || this.viewingProfile.walletInfo == null)
                    return false;
                return true;
            },
            onCancel() {
                this.$router.push('/users/profile');
            },
            onAddBankAccount() {
                this.$router.push('/users/addbankaccount');
            },
        },
	}
</script>